import { hatchEggs, sellEggs } from "../../utils/contract";
import { Button } from "../Button/Button";
import Label from "../Label/Label";
import styles from "./UserView.module.css";

export interface IUserViewProps {
   miners: string;
   grog: string;
   grogBNB: string;
   invested: string;
   withdrawal: string;
}

export const UserView = ({
   miners,
   grog,
   grogBNB,
   invested,
   withdrawal,
}: IUserViewProps) => {
   return (
      <div className={`${styles["card-wrapper"]}`}>
         <Label label="Pirate Miners" value={miners} />
         <Label label="Your TVL" value={grogBNB} unit="BNB" />
         {/* <Label label="Total Invested" value={invested} />
         <Label label="Withdrawn" value={withdrawal} /> */}
         {/* <div className={styles.total}>{grogBNB}</div> */}
         <div className={styles.tools}>
            {/* <Button onClick={() => sellEggs()} bgColor="green">
               POCKET
            </Button> */}
            <Button
               onClick={() => {
                  hatchEggs();
               }}
               bgColor="red"
            >
               COMPOUND
            </Button>
         </div>
      </div>
   );
};

import { ethers } from "ethers";
import abi from "./api.json";

export const contractAddress = "0x5D4bf1B907539A0ab08FED1522b289BDd30488A9";

export const getContract = () => {
   const provider = new ethers.providers.Web3Provider((window as any).ethereum);
   const signer = provider.getSigner();

   const contract = new ethers.Contract(contractAddress, abi as any, signer);

   return contract;
};

export const hatchEggs = (callback?: () => void) => {
   const contract = getContract();
   contract.hatchEggs();
   contract.on("hatchEvent", async () => {
      callback && callback();
   });
};

export const sellEggs = (callback?: () => void) => {
   const contract = getContract();
   contract.sellEggs();
   contract.on("sellEvent", async () => {
      callback && callback();
   });
};

export const buyEggs = (
   referrer: string,
   amount: string,
   callback?: () => void
) => {
   const contract = getContract();
   contract.buyEggs(referrer, {
      value: ethers.utils.parseEther(amount),
      gasLimit: 1000000,
   });
   contract.on("buyEvent", async () => {
      callback && callback();
   });
};

export const getContractData = async (userAddress: string | null) => {
   const contract = getContract();
   const data = await contract.getContractData(userAddress);
   return data;
};

export const calculateEggBuy = async (amount: string) => {
   const contract = getContract();
   const data = await contract.calculateEggBuySimple(
      ethers.utils.parseEther(amount)
   );
   return data;
};

import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Header } from "./components/Header/Header";
import { UserView } from "./components/UserView/UserView";
import { ReferralView } from "./components/ReferralView/ReferralView";
import { ContractView } from "./components/ContractView/ContractView";
import { injected } from "./utils/connector";
import { getContractData } from "./utils/contract";
import { PDF } from "./components/PDF/PDF";
import Box from "./components/Box/Box";
import Links from "./components/Links/Links";
import { Button } from "./components/Button/Button";
import GlassView from "./components/GlassView/GlassView";
import { Help } from "./components/Help/Help";
import images from "./images";
import { Welcome } from "./components/Welcome/Welcome";
import { DayCounter } from "./components/DayCounter/DayCounter";
import Images from "./images";

function App() {
   const { active, account, activate } = useWeb3React();
   const [stats, setStats] = useState({
      userMiners: "---",
      userEggs: "---",
      userEggsBnb: "---",
      userInvestment: "---",
      userWithdrawal: "---",
      userLastHatch: "0",
      refBonus: "---",
      totalInvestment: "---",
      totalUsers: "---",
      userTVL: "---",
      contractBalance: "---",
   });
   // const [count, setCount] = useState("0");
   const [showModal, setShowModal] = useState(false);
   const [showWelcome, setShowWelcome] = useState(true);
   const [tradingModal, setTradingModal] = useState(false);

   const loadData = useCallback(
      async function loadData() {
         if (account) {
            const data = await getContractData(account);
            setStats({
               userMiners: data[0].toNumber().toFixed(1),
               userEggs: data[1].div(10000000).toNumber().toFixed(),
               userEggsBnb: ethers.utils.formatEther(data[2]).substring(0, 5),
               userInvestment: ethers.utils.formatEther(data[5]).substring(0, 5),
               userWithdrawal: ethers.utils.formatEther(data[6]).substring(0, 5),
               userLastHatch: data[7].toNumber(),
               // userDividends: data[2].toString(),
               refBonus: data[4].div(10000000).toNumber().toFixed(),
               contractBalance: ethers.utils.formatEther(data[8]).substring(0, 5),
               // marketNewtella: data[4].toString(),
               totalInvestment: ethers.utils.formatEther(data[11]).substring(0, 5),
               totalUsers: data[10].toNumber(),
               userTVL: ethers.utils.formatEther(data[12]).substring(0, 5),
            });
         }
      },
      [account]
   );

   // function onEnter() {
   //    setShowWelcome(false);
   //    audioRef.current?.play();
   // }

   useEffect(() => {
      activate(injected);
      if (active) {
         loadData();
      }
   }, [activate, active, loadData]);

   useEffect(() => {
      const interval = window.setInterval(() => {
         loadData();
      }, 1000);
      return () => clearInterval(interval);
   }, [loadData]);

   // const interval = useRef(0);

   // useEffect(() => {
   //    interval.current = window.setInterval(function () {
   //       var now = new Date().getTime();

   //       // Find the distance between now and the count down date
   //       var distance = new Date(Date.UTC(2022, 5, 1, 16)).getTime() - now;

   //       // Time calculations for days, hours, minutes and seconds
   //       var days = Math.floor(distance / (1000 * 60 * 60 * 24));
   //       var hours = Math.floor(
   //          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
   //       );
   //       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
   //       var seconds = Math.floor((distance % (1000 * 60)) / 1000);

   //       setCount(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

   //       return () => clearInterval(interval.current);
   //    }, 1000);
   // }, []);

   return (
      <div className="main-wrap">
         {/* <Landing ref={audioRef} /> */}
         {/* <Nav /> */}
         <Header />
         <div className="presale">
            <a href="https://bmptoken.com" className="link" target="_blank" rel="noreferrer">
               BUY BMP TOKEN
            </a>
         </div>
         <div className="trading">
            <img src={Images.blinking} alt="Trading live opportunities" />
            <a
               href="#"
               onClick={(e) => {
                  e.preventDefault();
                  setTradingModal(true);
               }}
            >
               <p>
                  <span>TRADING</span> Opportunity...
               </p>
            </a>
         </div>
         <Help showHelp={tradingModal} onClose={() => setTradingModal(false)}>
            <div>
               <h1>Opportunity 💵💵💵💵</h1>
               <h4>Extra income BMP brand 🚨</h4>
               <p>2 options available 💰</p>
               <p>Option 1👇👇</p>
               <p>Requirements ☀️</p>
               <p>1👉Have at least 1000 tokens in the wallet 🚨 </p>
               <p>
                  2👉 Have an active Word cup deposit and mandatory participation in all future
                  brand developments 🚨
               </p>
               <p>
                  Always and only solely from the wallet where you hold the tokens ( another wallet
                  will not be valid to get the trading commissions)
               </p>
               <p>Option 2</p>
               <p>1👉Have in your wallet at least 700 tokens 🚨🚨🚨</p>
               <p>
                  2👉Have an active deposit in all brand dapps created (BnB pirates, fast furius,
                  paper house, wadjet egyptian miner, World cup) All exclusively in the wallet where
                  you hold the tokens no other wallets than that are accepted participate in all
                  future developments
               </p>
               <p>What will you gain?💵💰💵💰💵💰💵💰</p>
               <p>
                  Each month liquidity will be added in trading and each month 10% of trading
                  earnings will be calculated and divided to all eligible people who filled out the
                  form Payment will be made in busd in the wallet where you hold the
                  token💵💵💵💵💵💵
               </p>
               FOR INFORMATION WRITE TO @Silene_Oliveira_TPH @Mikecryptobusiness
               <br />
               remember to fill out the form👇👇👇👇👇
               <a href="https://forms.gle/nsd2ZDmqgtm898MPA">Google Form</a>
            </div>
         </Help>
         <div className="content">
            {/* <Box>
               <a href="https://fandfbnb.com/?ref=0xe793B0b8b19df008205AE8A5ec3984C2F11A39A1">
                  <img src={images.fast} style={{ width: "100%" }} />
               </a>
            </Box> */}
            <DayCounter start={1657892476} />
            <Links />
            {!active && (
               <Box>
                  <Button full onClick={() => activate(injected)}>
                     Connect
                  </Button>
               </Box>
            )}
            {/* <Timer timeStr={count} /> */}
            <Box gradient style={{ backgroundColor: "#f0b059" }}>
               <ContractView
                  totalUsers={stats.totalUsers}
                  totalInvestment={stats.contractBalance}
               />
            </Box>
            <div className="appslink">
               <a href="https://eu.jotform.com/app/bmpbrand/bmp-brand">
                  <video autoPlay loop muted playsInline>
                     <source src="logobmp.mp4" type="video/mp4" />
                  </video>
               </a>
               <a href="https://wadjetegyptianminer.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc">
                  <video autoPlay loop muted playsInline>
                     <source src="logowadjet.mp4" type="video/mp4" />
                  </video>
               </a>
               <a href="https://fandfbnb.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc">
                  <video autoPlay loop muted playsInline>
                     <source src="logoff.mp4" type="video/mp4" />
                  </video>
               </a>
               <a href="https://paperhouseminer.org/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc">
                  <video autoPlay loop muted playsInline>
                     <source src="logopaper.mp4" type="video/mp4" />
                  </video>
               </a>

               <a
                  href="https://bmpworldcup.com/app?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
                  title="Marketing"
               >
                  <video autoPlay loop muted playsInline>
                     <source src="worldcup.mp4" type="video/mp4" />
                  </video>
               </a>
               <a href="https://t.me/ilprofessorelacasadicartaa" title="Marketing">
                  <video autoPlay loop muted playsInline>
                     <source src="dial.mp4" type="video/mp4" />
                  </video>
               </a>
            </div>
            <Box>
               <GlassView checkpoint={parseInt(stats.userLastHatch)} grogBNB={stats.userEggsBnb} />
            </Box>
            <Box>
               <UserView
                  miners={stats.userMiners}
                  grog={stats.userEggs}
                  grogBNB={stats.userTVL}
                  invested={stats.userInvestment}
                  withdrawal={stats.userWithdrawal}
               />
            </Box>
            <Box header="Referrals">
               <ReferralView refBonus={stats.refBonus} totalInvestment={stats.totalInvestment} />
            </Box>
            <Box>
               <PDF />
            </Box>
            <Box header="Sustainability">
               <p>
                  BNB MINER PIRATES is sustained by continued community support, just like every
                  other crypto coin, token or project. The difference is, BNB MINER PIRATES also has
                  an algorithm that doesn't allow others to instantly dump their coins on the
                  community.
               </p>
               <p>
                  BNB MINER PIRATES also allows you to stabilize and increase your TVL by taking
                  long-term advantage of its compound feature.
               </p>
            </Box>
            <Box header="Miner Info">
               <p>
                  BNB Miner Pirates is designed for individuals with a long-term vision. It is not
                  for those who want instant profits that ultimately harm others. Miners can be
                  viewed as internal tokens whose value rises and falls based on the combined
                  actions of the community, just like any other coin or token that you may hold.
                  But, unlike your average coins and tokens, BNB Miner Pirates allows you to
                  stabilize and increase your TVL by taking long-term advantage of its compound
                  feature.
               </p>
               <p>
                  TVL is your current estimated "total value locked", an estimated value of your
                  total miners. Once miners are hired, they work for you indefinitely, therefore
                  your TVL can not be withdrawn in one lump sum. When you hire miners , they fill
                  your barrel with BNB throughout the day with an estimated daily total of 3% of
                  your TVL. You can pocket or compound the BNB accumulated in your barrel at any
                  time. Pocketing too often will ensure a decrease in TVL, which in turn will ensure
                  a decrease in your daily payouts. The value of miners continuously increases and
                  decreases throughout the day, therefore it is normal to see your personal TVL
                  fluctuating as your barrel continues to fill.
               </p>
            </Box>
            <Box header="Verified Public Contract">
               <p>
                  The BNB Miner Pirates contract is public, verified and can be viewed here on{" "}
                  <a href="https://www.bscscan.com/address/0x5D4bf1B907539A0ab08FED1522b289BDd30488A9">
                     BSCScan
                  </a>
                  .
               </p>
            </Box>
         </div>
         <Help
            showHelp={showWelcome}
            onClose={() => {
               setShowWelcome(false);
               setShowModal(true);
            }}
         >
            <div>
               <h4>📰 BMP BRAND NEWS 📰</h4>
               <p>
                  ✔️ Signed partnership agreement with one of the world's largest brokers TOP FX.
                  This will generate the first profits and will bring new liquidity to all our
                  Dapps.
               </p>
               <p>✔️ Also shortly there will be the launch of the next dapp.</p>

               <p>✔️ Opportunity Contest still active.</p>

               <p>
                  🤔 For info on how to participate, join our official channel or send a private
                  message to 📱 @Silene_Oliveira_TPH
               </p>

               <p>
                  <a href="https://t.me/BMPBrandDDB">📱OFFICIAL CHANNEL</a>
               </p>
               <h4>💪 BMP BRAND CHOOSE THE FUTURE IN DAPP. THE BMP BRAND IS HERE NOW‼</h4>
            </div>
         </Help>
         <Help showHelp={showModal} onClose={() => setShowModal(false)}>
            <div>
               <h4>🕶👍VIP CONTEST 👍🕶</h4>

               <p>STARTING FROM 5th DECEMBER YOU WILL HAVE 2 chances to join in the VIP👑 group:</p>

               <p>🔅 Limited pass 🎟</p>
               <p>🔆 Unlimited Pass 🎫</p>

               <h4>🔩 GAME RULES 🎮</h4>

               <p>
                  PASS LIMITED 🎟 to stay until the launch and get the world preview link along with
                  the other group benefits of the next dapp.
               </p>
               <p>➡️ Deposit 0.5 Bnb or more in 2 any Dapp (0.5+0.5) of the brand or</p>
               <p>➡️ Deposit 1 BnB or more in any one Dapp.</p>

               <p>UNLIMITED PASS 🎫 to the VIP group 👑 to stay forever.</p>

               <p>➡️ Deposit 2 BnB or more in 2 Dapps (1+1) of the brand.</p>

               <p>➡️ Deposit 2 BnB or more in any Dapp.</p>

               <p>
                  ☁️ FOR INFO Contact{" "}
                  <a href="https://t.me/Silene_Oliveira_TPH">@Silene_Oliveira_TPH</a>
               </p>
               <p>
                  <a href="https://forms.gle/xthCHevNqYVrMedc9">
                     ✍️ FILL OUT THE FORM to be added‼️
                  </a>
               </p>

               <p>
                  {" "}
                  🆘 NB. THE CONTEST IS NOT RETROACTIVE. No deposits prior to the launch date are
                  allowed.
               </p>

               <p>💎💎💎💎💎💎💎💎💎💎💎</p>

               <h4>🕶👍VIP CONTEST 👍🕶</h4>

               <p>A PARTIRE DAL 5 DICEMBRE AVRAI 2 possibilità per entrare nel gruppo Vip👑:</p>

               <p>🔅 Pass limitato 🎟</p>
               <p>🔆 Pass Illimitato 🎫</p>

               <h4>🔩 REGOLE DI GIOCO 🎮</h4>

               <p>
                  PASS LIMITATO 🎟 per rimanere fino al lancio e ottenere il link in anteprima
                  mondiale insieme agli altri benefici del gruppo, della prossima dapp uscente.
               </p>

               <p> ➡️ Deposita 0.5 Bnb o più in 2 qualsiasi Dapp (0.5+0.5) del brand o</p>

               <p> ➡️ Deposita 1 BnB o più in una qualsiasi Dapp.</p>

               <p>PASS ILLIMITATO 🎫 al gruppo Vip👑 per rimanere per sempre.</p>

               <p>➡️ Deposita 2 Bnb o più in 2 Dapp (1+1) del brand.</p>

               <p>➡️ Deposita 2 BnB o più in una qualsiasi Dapp.</p>

               <p>💎 GRUPPO VIP👑 IL MASSIMO</p>

               <p>
                  ☁️ PER INFO Contatta{" "}
                  <a href="https://t.me/Silene_Oliveira_TPH">@Silene_Oliveira_TPH</a>
               </p>
               <p>
                  <a href="https://forms.gle/xthCHevNqYVrMedc9">
                     ✍️ COMPILA IL FORM per essere aggiunto‼️
                  </a>
               </p>

               <p>
                  🆘 NB. IL CONTEST NON È RETROATTIVO. Non sono ammessi depositi precedenti la data
                  di lancio.
               </p>
            </div>
         </Help>

         {/* <Footer /> */}
         {/* <Audio ref={audioRef} /> */}
      </div>
   );
}

export default App;

import bsc from "./assets/bsc.png";
import bnb from "./assets/bnb.png";
import jotform from "./assets/jotform.png";
import logo from "./assets/logo.png";
import help from "./assets/help.png";
import linktee from "./assets/linktee.png";
import tel from "./assets/tel.png";
import fast from "./assets/fast.jpg";
import blinking from "./assets/blinking_new.gif";
const Images = {
   bsc,
   bnb,
   jotform,
   help,
   logo,
   linktee,
   tel,
   fast,
   blinking,
};

export default Images;

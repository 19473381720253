import styles from "./Header.module.css";
import Images from "../../images";

export function Header() {
   return (
      <div className={styles.wrap}>
         <div className={styles.logo}>
            <img src={Images.logo} alt="Newtella" />
         </div>
         <div className={styles.info}>
            <ul>
               <li>Earn Unlimited</li>
               <li>Upto 3% Daily</li>
               <li>Most Sustainable</li>
               <li>Smart Contract</li>
            </ul>
         </div>
      </div>
   );
}
